import { Grid, Typography } from '@mui/material';
import React from 'react';
import { BlueCard, WhiteCard } from '../styles/App.styles';
import Title from '../Title/Title';
import { Description, Service, Text, ServiceContainer } from './Services.styles';

function Services() {
  const services = [
    {
      src: '/assets/img/service_1.svg',
      text: 'Otorgamiento de créditos con scoring propio'
    },
    {
      src: '/assets/img/service_2.svg',
      text: 'Adquisición y administración de carteras originadas por terceros'
    },
    {
      src: '/assets/img/service_3.svg',
      text: 'Comercializacion digital a partir de onboarding multimarca'
    },
    {
      src: '/assets/img/service_4.svg',
      text: 'Disponibilidad de nuestra billetera digital de forma gratuita'
    }
  ];
  const people = [
    {
      title: 'Hugo Martino',
      subtitle: 'Gerente General',
      text: 'Más de 20 años de experiencia en el mercado de consumo en Argentina, y LatAm. Head de PROA División Consumo de HSBC para Argentina y consultor para LatAm. Ha desarrollado tareas gerenciales en Compañía Financiera Argentina (Efectivo Sí), Banco Columbia, Finvercon S.A. (WELLS FARGO) y otras compañías del mercado. '
    },
    {
      title: 'Rodolfo Olenchuk',
      subtitle: 'Senior Credit Analyst',
      text: 'Más de 20 años de experiencia en gestión empresarial en compañías como Techint, Symphonic Strategies Inc. y Capsol Partners. Desarrollando tareas gerenciales en las áreas de gestión de proyectos, planificación financiera, gestión de carteras, estrategia y planificación empresarial. Ingeniero Industrial ITBA, MBA Universidad George Washington.'
    },
    {
      title: 'Juan Manuel Cordon',
      subtitle: 'Gerente de cobranzas y operaciones',
      text: '15 años de experiencia en el mercado financiero en diversas entidades, Caja de Crédito Cuenca, Ceibo Créditos y Épico S.A.Desarrollando tareas gerenciales en Cobranzas y Operaciones.'
    }
  ];
  return (
    <Grid container flexDirection="column">
      <Title>Nuestros servicios</Title>
      <Text className="wow fadeInAnimation" data-wow-delay="200ms">
        El objetivo de la compañía es facilitar el acceso a servicios financieros para que sus clientes cumplan con sus deseos y
        proyectos. Con el apoyo del equipo profesional y aplicando tecnología de vanguardia, ofrecemos las mejores alternativas
        del mercado en cuanto a seguridad, velocidad y simplicidad.
      </Text>
      <ServiceContainer container flexDirection="row" justifyContent="center">
        {services.map((service, index) => (
          <Service
            xs={6}
            lg={3}
            container
            item
            key={service.text}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
          >
            <Grid item className="wow zoomIn" data-wow-delay={`${index * 2}00ms`}>
              <img src={service.src} />
            </Grid>
            <Grid item className="wow fadeInAnimation" data-wow-delay={`${index * 2}00ms`}>
              <Description>{service.text}</Description>
            </Grid>
          </Service>
        ))}
      </ServiceContainer>
      <BlueCard container className="wow fadeInAnimation">
        <Grid item>
          <Typography variant="h1" textAlign="center">
            {' '}
            Sobre nosotros
          </Typography>
        </Grid>
        <Grid
          container
          sx={{ marginTop: '2rem', justifyContent: 'center', flexDirection: { xs: 'column', lg: 'row' } }}
          alignItems="stretch"
          alignContent="center"
          gap={2}
          flexWrap="nowrap"
        >
          {people.map((person, index) => (
            <WhiteCard
              flexDirection="column"
              alignContent="center"
              key={person.text}
              item
              container
              className="wow fadeInAnimation"
              data-wow-delay={`${index * 2}00ms`}
            >
              <Typography variant="h3" textAlign="center">
                {person.title}
              </Typography>
              <Typography variant="h4" textAlign="center">
                {person.subtitle}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {person.text}
              </Typography>
            </WhiteCard>
          ))}
        </Grid>
      </BlueCard>
    </Grid>
  );
}

export default Services;
